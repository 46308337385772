import React from "react";
import Button from "@material-ui/core/Button";

const CapsuleButton = ({ onClick, color, label, className }) => {
  return (
    <Button
      onClick={onClick}
      className={`${
        color === "white" ? "edit-btn-w" : "edit-btn"
      } ${className}`}
      variant="contained"
      style={{ boxShadow: "none", width: "120px" }}
    >
      {label}
    </Button>
  );
};

export default CapsuleButton;
